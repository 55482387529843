<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('module.queue') }}</h5>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{ $t('common.tanggal') }}:</label>
            <div class="col-lg-6">
              <date-picker
                ref="datePickerVue"
                :changedValue.sync="date"
                :id-date-picker="'tanggal_kunjungan'"
                :picker-value="date"
                place-holder="DD/MM/YYYY"
                :is-validate="true"
                start-date="-0d"
                end-date=""
              ></date-picker>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="example-text-input"
              class="col-3 col-form-label"
            >{{ $t('module.queueUnit') }}:</label>
            <div class="col-lg-6">
              <unit-antrian
                v-model="queueUnitId"
                :validation="'required'"
                :value-label="'id'"
              ></unit-antrian>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="example-text-input"
              class="col-3 col-form-label"
            >{{ $t('common.queueType') }}:</label>
            <div class="col-lg-6">
              <div class="kt-radio-inline">
                <label class="kt-radio">
                  <input
                    type="radio"
                    value="MANUAL"
                    v-model="queueType"
                    checked
                  /> MANUAL
                  <span></span>
                </label>
                <label class="kt-radio">
                  <input
                    type="radio"
                    value="AUTO"
                    v-model="queueType"
                  /> AUTO
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-clean btn-bold btn-upper btn-font-md"
            data-dismiss="modal"
            @click="clearData()"
          >{{ $t('button.close') }}</button>
          <button
            type="button"
            class="btn btn-default btn-bold btn-upper btn-font-md"
            @click="submitData()"
          >{{ $t('button.save') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnitAntrian from "../../_select/UnitAntrian";
import DatePicker from "../../_general/DatePicker";
import SweetAlertTemplate from "../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const QueueUserRepository = RepositoryFactory.get("queueUser");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  components: {
    UnitAntrian,
    DatePicker
  },
  data() {
    return {
      date: "",
      queueUnitId: null,
      queueType: "MANUAL"
    };
  },
  props: {
    modalName: { type: String }
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  methods: {
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    submitData: function () {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {
          var params = {
            date: this.date,
            queueType: this.queueType,
            queueUnitId: this.queueUnitId
          };

          let instance = new SweetAlert().showConfirm({
            swalTitle: "Apakah Anda yakin?",
            swalText: "Data Antrian Pasien akan ditambahkan.",
            swalType: "info",
            onConfirmButton: function () {
              blockUI.blockModal();
              vx.create(params);
            },
            onCancelButton: function () {
              blockUI.unblockModal();
            }
          });
        }
      });
    },
    async create(payload) {
      var vx = this;

      await QueueUserRepository.createQueueUserAuto(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data Antrian Pasien berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Data Antrian Pasien gagal ditambahkan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    }
  },
  mounted() {
    this.date = moment().format("YYYY-MM-DD");
    $("#" + this.modalName).modal();
  }
};
</script>
